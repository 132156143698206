.form-fields {
  display: flex;
  flex-direction: column;
  gap: 11px;
  width: 100%;
  max-width: 320px;

  a, a:link, a:visited, a:focus, a:hover, a:active {
    text-decoration: underline;
    color: #005767;
  }
}