@font-face {
  font-family: 'HelveticaNeueBold';
  src: url("./fonts/HelveticaNeueBold.woff2") format("woff2"), url("./fonts/HelveticaNeueBold.woff") format("woff");
  font-weight: bold;
  font-style: normal
}

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: Helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #2a2d30;
  font-size: 10px;

  @media screen and (min-width: 640px) {
    font-size: 12px;
  }
}

p {
  text-align: left;
  font-size: 10px;
  line-height: 13px;
  margin: 0;

  @media screen and (min-width: 640px) {
    font-size: 12px;
  }
}