.section {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 20px;

  &--white {
    background-color: #fff;
  }

  &--grey {
    background-color: #ddd;
  }

  &--primary {
    background-color: #1F839F;
    color: #fff;
  }

  &--dark {
    background-color: #2a2d30;
    color: #fff;
  }

  &--flat {
    padding: 11px 20px;
  }
}