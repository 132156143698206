.page-title {
  font-family: "HelveticaNeueBold", sans-serif;
  font-weight: 700;
  color: #005767;
  margin: 0;
  text-align: center;
  text-transform: uppercase;
  font-size: 24px;
  line-height: 24px;
  padding-top: 6px;
}