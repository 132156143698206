.radio-input {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #005767;
  cursor: pointer;
  user-select: none;

  input[type="radio"] {
    margin: 0 4px 0 0;
    border: 1px solid #005767;
    cursor: pointer;
  }
}