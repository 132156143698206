.select-input {
  font-family: Helvetica, sans-serif;
  transition: 200ms ease border-color;
  border-radius: 4px;
  padding: 8px 6px;
  border: 1px solid #fff;
  outline: none;
  font-size: 10px;
  cursor: pointer;

  @media screen and (min-width: 640px) {
    font-size: 12px;
  }

  &:focus {
    border-color: #1f839f;
  }

  &:invalid,
  &:not(.has-value) {
    color: #9D9D9D;
  }
}