.checkbox-input {
  font-family: "HelveticaNeueBold", sans-serif;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: #005767;
  cursor: pointer;
  user-select: none;

  input[type="checkbox"] {
    margin: 0 4px 0 0;
    cursor: pointer;
  }

  span {
    padding-top: 4px;
    line-height: 14px;
  }

  a, a:hover, a:visited, a:focus {
    text-decoration: underline;
    display: inline-block;
    color: #005767;
  }
}