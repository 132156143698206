.CalendarDay {
  flex-basis: 20%;
}

.CalendarDay__today {
  &, &:hover, &:active {
    font-weight: 700;
  }
}

.CalendarDay__selected,
.CalendarDay__selected_span {
  &, &:hover, &:active {
    background: #1F839F;
    color: #fff;
  }
}