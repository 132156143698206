.button {
  font-family: Helvetica, sans-serif;
  background-color: #E60011;
  color: #fff;
  outline: none;
  border: 0 none;
  border-radius: 4px;
  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
  width: 200px;
  height: 36px;
  align-self: center;
  text-decoration: none;
  cursor: pointer;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;

  &:active,
  &:focus,
  &:visited {
    text-decoration: none;
  }
}