.app {
  background-color: #ddd;
}

.header {
  background-color: #fff;
  padding: 16px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.social-media {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  max-width: 320px;

  &__link {
    display: flex;
    outline: none;
    margin: 5px;
  }

  &__logo {
    width: 42px;
    height: 42px;
  }
}

.footer__nav {
  display: flex;
  flex-direction: column;
  align-items: center;

  a, a:hover, a:focus, a:active {
    text-decoration: none;
    font-size: 14px;
    font-weight: 700;
    line-height: 18px;
    white-space: nowrap;
    color: #fff;
    margin: 5px 0;
  }

  a:hover {
    text-decoration: underline;
  }
}

.logo {
  width: 126px;
  pointer-events: none;
}