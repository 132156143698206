.form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.selected-date {
  font-family: "HelveticaNeueBold", sans-serif;
  display: flex;
  font-size: 12px;
  font-weight: 700;
  align-items: center;
  justify-content: center;
  max-width: 320px;
  text-align: center;
  padding: 0 20px;
}

.button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0 0;

  + .error-message {
    margin: 10px 0 0;
    padding: 0 20px;
  }
}

.radio-group {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.opening-hours {
  margin-top: 5px;
}

.hint {
  color: #9D9D9D;
}

.error-message {
  font-family: "HelveticaNeueBold", sans-serif;
  color: #E60011;
  font-size: 12px;
  line-height: 13px;
  margin: 5px 0 0;
  font-weight: 700;
  max-width: 320px;
}